import { useTranslation } from 'next-i18next';
import BlogCard from '../blog/BlogCard';

export default function BlogSection({ posts }) {
	const { t } = useTranslation('common');
	if (!posts || posts.length === 0) {
		return null;
	}
	return (
		<section className="bg-white py-16">
			<div className="mx-auto max-w-7xl px-4 lg:px-8">
				<div className="mx-auto max-w-3xl text-center">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{t(`company.blog.title`)}
					</h2>
					<h3 className="mt-2 text-lg leading-8 text-gray-600">
						{t(`company.blog.subtitle`)}
					</h3>
				</div>
				<div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-y-20 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					{posts.map((post) => (
						<BlogCard key={post.link} post={post} />
					))}
				</div>
			</div>
		</section>
	);
}
