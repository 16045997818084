import Image from 'next/image';
import Link from 'next/link';

export default function BlogCard({ post, showImage = false }) {
	return (
		<article
			key={post.link}
			className="relative isolate flex flex-col items-start"
		>
			{showImage && post.imageUrl && (
				<div className="relative mb-8 w-full ">
					<Image
						width={400}
						height={250}
						className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
						src={post.imageUrl}
						alt={post.title}
						title={post.title}
					/>
					<div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
				</div>
			)}
			<div className="max-w-xl">
				{/* <div className="flex items-center gap-x-4 text-xs">
					<time dateTime={post.date} className="text-gray-500">
						{format(new Date(post.date), 'MMM dd, yyyy')}
					</time>
				</div> */}
				<div className="group">
					<h2 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
						<Link href={post.link} rel="noreferrer noopener" target="_blank">
							<span className="absolute inset-0" />
							{post.title}
						</Link>
					</h2>
					<div
						className="mt-5 text-sm leading-6 text-gray-600 line-clamp-6"
						dangerouslySetInnerHTML={{
							__html: post.excerpt,
						}}
					></div>
				</div>
			</div>
		</article>
	);
}
