import { classNames } from '@/lib/utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { i18nRoute } from 'routes';

export default function CompanyLink({ className, children, company }) {
	const { i18n } = useTranslation('common');

	return (
		<Link
			title={company.name}
			href={i18nRoute(`/companies/[companyId]`, i18n.language, {
				companyId: company.id,
			})}
			className={classNames('focus:outline-none', className)}
		>
			{children ? children : company.name}
		</Link>
	);
}
